/** @format */

import React from 'react';
import ProjectBar from '../components/ProjectBar';

import Layout from '../components/layout';
import SmallPhoto from '../components/SmallPhoto';
import Photo from '../components/Photo';
// import Text from "../components/Text"
import Title from '../components/Title';
// import Test from '../components/test.md';
import d2 from '../images/dynasty2.jpg';
import d3 from '../images/d3.jpg';
import drivana from '../images/drivana.jpg';
import bs3000 from '../images/bs3000.jpg';

import nugg from '../images/nuggmock.png';
import arbys from '../images/arbys.png';
import d1 from '../images/dynastyother.jpg';
import oldlf from '../images/oldlfshad.png';
import lf1 from '../images/lf1.png';
import lf2 from '../images/lf2.png';
import lf3 from '../images/lf3.png';

import windsor from '../images/windsor.png';
import mc1 from '../images/mc1.png';
import mc2 from '../images/mc2.jpg';
import candc from '../images/cardsandculture.png';

import { navigate } from 'gatsby-link';

import lu from '../images/lucover2.png';
import mb from '../images/mbcoverlarge.png';
import penskecover from '../images/penskecoverlarge.png';
import otherwork from '../images/otherwork.jpg';
import SmallerPhoto from '../components/SmallerPhoto';

const OtherWork = () => {
    return (
        <Layout>
            {/* <ProjectBar /> */}

            {/* <div className="section" id="content-desktop">
                <div className="container">
                    <div className="project-bar-container">
                        <div
                            className="project-container"
                            onClick={() => navigate(`/liveunlimited`)}>
                            <img className="project-photo" src={lu}></img>
                            <div className="project-name">Live Unlimited</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/penske/`)}>
                            <img className="project-photo" src={cms}></img>
                            <div className="project-name">Penske</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/mallardbay/`)}>
                            <img className="project-photo" src={mb}></img>
                            <div className="project-name">Mallard Bay</div>
                        </div>
                        <div
                            className="project-container-active"
                            onClick={() => navigate(`/otherwork/`)}>
                            <img className="project-photo" src={dhl}></img>
                            <div className="project-name">Other Work</div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <div
                            onClick={() => {
                                navigate('/#work');
                            }}
                            className="back">
                            <div className="subtitle">
                                <b>←</b> Back to Projects
                            </div>
                        </div>
                        {/* <div className="categories">{props.categories}</div> */}
                        <h1>Other work</h1>
                        <h3>
                            A collection of website, app, and one-off designs.
                        </h3>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <h3 className="">UI/UX Design</h3>
                        <h1>Credit Card Company Proposal</h1>
                        <p>
                            These screens were designed for a proposal for a top
                            4 credit card company. (Due to an NDA, the logo has
                            been replaced with a generic one.)
                        </p>
                        <p>
                            I was given a user journey and turned it into the
                            following screens in less than 48 hours to submit.
                            The designs received praise from the client and
                            helped us advance to the oral rounds.
                        </p>
                    </div>
                </div>
            </div>
            <Photo
                link={mc1}
                subtitle="Home Page, Search Results, Trip Info & Hassle Free Arrival, One-Click Check In Screens"
            />
            <Photo
                link={mc2}
                subtitle="Hassle Free Arrival Notification Screens"
            />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper"></div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <h3 className="">UI/UX Design</h3>
                        <h1>Penske Location Finder</h1>
                        <p>
                            I redesigned an existing internal Penske application
                            that various teams use to locate Penske locations.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={oldlf} subtitle="Original" />
            <SmallPhoto link={lf1} subtitle="Home Page" />
            <SmallPhoto link={lf2} subtitle="Search Results" />
            <SmallPhoto link={lf3} subtitle="Location Page" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper"></div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <h3 className="">UI/UX Design</h3>
                        <h1>Dynasty Trusts</h1>
                        <p>
                            Dynasty was a freelance client. I designed various
                            features and pages for their web app, as well as a
                            redesigned web page.
                        </p>
                        <div
                            onClick={() => {
                                navigate(
                                    'https://www.figma.com/proto/2kk48hyfGikk8U5J8RjMgO/Work-Samples?page-id=0%3A1&node-id=23-2170&viewport=-1088%2C471%2C0.18&t=qpiGB5X6ba4cjxiO-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=23%3A2170&show-proto-sidebar=1',
                                );
                            }}
                            className="figmalink">
                            <div className="subtitle">
                                Dynasty Site Figma Prototype
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Photo link={d1} subtitle="Mobile and Web Hero Section" />
            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <div
                            onClick={() => {
                                navigate(
                                    'https://www.figma.com/proto/2kk48hyfGikk8U5J8RjMgO/Untitled?page-id=0%3A1&node-id=22-1836&viewport=-1088%2C471%2C0.18&t=T4NWVYuzBzdF1b2L-1&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=2%3A11707',
                                );
                            }}
                            className="figmalink">
                            <div className="subtitle">
                                Dynasty Partner Page Figma Prototype
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Photo link={d2} subtitle="Dynasty Partner Page" />
            <Photo
                link={d3}
                subtitle="Various features I designed: Trust Optimization Tracker, Premium Documents, Personal Video Message UI"
            />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper"></div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>
            {/* <Title
                title="Cards and Culture App"
                subtitle="Unreleased application for sports card and memorabilia investing."
                categories="UI/UX Design"
            /> */}

            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <h3 className="">UI/UX Design</h3>
                        <h1>Drivana</h1>
                        <p>
                            Mexico's AirBnb for cars. Early stage startup. I
                            redesigned their home page.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={drivana} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper"></div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <h3 className="">
                            UI/UX Design, Front-End Development
                        </h3>
                        <h1>Breach Simulator</h1>
                        <p>
                            Breach Simulator is a digital tabletop exercise used
                            to practice cybersecurity incident response. I
                            redesigned the application and coded the front end.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={bs3000} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper"></div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>

            {/* <div className="section">
                <div className="container">
                    <div className="title-wrapper">
                        <h3 className="">
                            UI/UX Design, Front-End & Back-End Development
                        </h3>
                        <h1>Arby's</h1>
                        <p>
                            An app I made to practice app development, it points
                            you in the direction of the nearest Arby's... what
                            more could you want?
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto
                link={arbys}
                subtitle="The most useful app in existence"
            /> */}
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3></h3>
                        <p></p>
                    </div>
                </div>
            </div>
            {/* <ProjectBar /> */}

            <div className="section" id="content-desktop">
                <div className="container">
                    <div className="project-bar-container">
                        <div
                            className="project-container"
                            onClick={() => navigate(`/penske/`)}>
                            <img
                                className="project-photo"
                                src={penskecover}></img>
                            <div className="project-name">Penske</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/liveunlimited`)}>
                            <img className="project-photo" src={lu}></img>
                            <div className="project-name">Live Unlimited</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/mallardbay/`)}>
                            <img className="project-photo" src={mb}></img>
                            <div className="project-name">Mallard Bay</div>
                        </div>
                        <div
                            className="project-container-active"
                            onClick={() => navigate(`/otherwork/`)}>
                            <img
                                className="project-photo"
                                src={otherwork}></img>
                            <div className="project-name">Other Work</div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default OtherWork;
